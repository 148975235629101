<template>
  <div>
    <el-dialog title="上传答题卡"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">

      <el-form :model="form"
               ref="formRef"
               label-width="100px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="类型：">
              <el-radio-group v-model="form.type">
                <el-radio :label="1">扫描件</el-radio>
                <!-- <el-radio :label="2">手机拍照图片</el-radio> -->
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="!isAdd">
          <el-col :span="24">
            <el-form-item label="正反面：">
              <el-radio-group v-model="form.image_type">
                <el-radio :label="0">正面</el-radio>
                <el-radio :label="1">反面</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="点击上传：">
              <el-button class=""
                         type="primary"
                         @click="upload">上传
                <!-- :disabled="disabled" -->
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <div v-show="showProgress">
          <el-progress :percentage="percentage"
                       :status="status1"></el-progress>
          <!--  -->
        </div>
        <div class="request">
          <p>请按照如下要求上传答题卡：</p>
          <ol>
            <li v-if="isAdd">请确保上传答题卡的顺序是按照学生答题卡正反面排序。</li>
            <li>请确保扫描结果清晰方正。</li>
          </ol>
        </div>

      </el-form>

      <!-- <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="createSource">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: { type: 1, image_type: 0 },
      dialogVisible: false,
      isAdd: 1,
      percentage: 0,
      showProgress: false,
      status1: null,
      disabled: false
    }
  },
  methods: {
    upload () {
      this.percentage = 0
      this.$parent.uploadSubmit(this.form.type, this.form.image_type)
      this.showProgress = true
      this.status1 = null
    },
    handleClose () {
      this.$refs.formRef.resetFields()
      if (this.$parent.sitv) {
        clearInterval(this.$parent.sitv)
      }
      this.dialogVisible = false
      this.percentage = 0
      this.showProgress = false

    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}
::v-deep .dialog-footer {
  text-align: center;
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}

::v-deep .el-dialog {
  margin-top: 10vh !important;
}
.request {
  ol {
    list-style: zero;
  }
  padding: 10px 20px;
  line-height: 24px;
  background: #efefef;
  border-radius: 2px;
}
</style>